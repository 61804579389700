
.header {
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    padding: 0 20px; /* ヘッダーの内側の余白 */
    background-color: #aec6cf; /* ヘッダーの背景色 */
    border-bottom: 1px solid #ddd; /* 下部にボーダーを追加 */
    height: 80px;
    position: fixed;
    justify-content: space-between;
    z-index: 1;
  }
  .wrapper {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
  }
  .service-page-lead {
    display: inline-block;
    /* padding: 10px 20px; */
    /* background-color: #ff9999; 
    color: white; */
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
}
  .service-page{
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .service-page-scale-wrapper > img{
    width: 100%;
  }
  .service-page-scale-wrapper:hover {
    animation: scaleUp 0.5s forwards;
  }
  .service-page-scale-wrapper {
    padding: 100px 0 0 0;
    max-width: 350px; /* 幅を調整 */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .brand-name {
    text-align: center;
    font-size: 20px; /* 屋号のフォントサイズ */
    font-weight: bold; /* テキストを太字に */
    color: #333; /* テキストカラー */
  }
  .brand-name > h1,h2 {
    font-size: 20px; /* 屋号のフォントサイズ */
  }

.upload-container {
  max-width: 800px; /* 幅を調整 */
  /* margin: auto; */
  padding: 120px 20px 20px 20px;
  display: flex; /* Gridを有効化 */
  flex-direction: column;
}

.upload-container p {
  margin: 10px 0;
}

.file-input {
  width: 0; /* 変更 */
  height: 0;
}

.dimensions-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  overflow-y: scroll;
}

.volume-price, .dimensions-info {
  margin-top: 20px;
}

.volume-price p {
  font-size: 28px; /* フォントサイズを大きく */
  color: #d35400; /* 目を引く色に変更 */
  font-weight: bold; /* テキストを太字に */
  text-align: center; /* 中央揃えで表示 */
  margin: 20px 0; /* 上下の余白を適切に設定 */
  padding: 15px; /* 内側の余白を追加 */
  background-color: #f9ebea; /* 背景色を薄く設定 */
  border-radius: 8px; /* 角を丸く */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* ソフトな影を追加 */
}

.dimensions-info {
  background-color: #eef;
  border: 1px solid #dde;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.volume-price p, .dimensions-info p {
  margin: 0;
  color: #333;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px 0; /* 追加 */
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzone.dragover {
  background-color: #e9f5ff;
  border-color: #2196F3;
}

.model-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dimensions-info {
  margin: 0;
}

.remove-button {
  margin-left: 20px; /* 追加 */
  padding: 5px 10px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.remove-button:hover {
  background-color: #ff7875;
}
.page-title {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 24px; /* タイトルのフォントサイズを適宜調整 */
  font-weight: bold;
  margin-bottom: 10px;
}

.mobile {
  display: none;
}
.model-name {
  color: #333; /* 色を暗めに設定して読みやすく */
  font-weight: bold; /* 名前を強調 */
}

.modal-right {
  display: flex;
}

.pc {
  display: inline;
}
.model-quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* ボタン間の間隔 */
  }
  
  .model-quantity button {
    padding: 5px 10px; /* ボタンのサイズ調整 */
    font-size: 14px; /* テキストサイズ */
    color: #333; /* テキストカラー */
    background-color: #e7e7e7; /* ボタン背景色 */
    border: none; /* ボーダー削除 */
    cursor: pointer; /* カーソル変更 */
  }
  
  .model-quantity button:hover {
    background-color: #d4d4d4; /* ホバー時の背景色変更 */
  }
  
  .model-quantity span {
   min-width: 20px; /* 数量表示の最小幅 */
  text-align: center; /* 数量テキストを中央揃えに */
  }

  .service_button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

.service-page-title {
  margin: 0;
  /* font-weight: bold; */
}

.pc-title {
  display:inline
}
.mobile-title {
  display:none;
}
.service-page-description {
  margin: 0;
  font-size: 14px;
  color: gray;
}
/* 大きい画面サイズのときのスタイル */
@media screen and (max-width: 600px) {
  .service-page-lead {
    font-size: 16px;
}
.service_button {
  font-size: 20px;
}
  .dimensions-info {
    flex-direction: column;
  }
  .dimensions {
    display: flex;
  }
  .scale {
    display: flex;
  }
  .wrapper{
    flex-direction: column;
  }
  .page-title {
    font-size: 18px;
  }
  .mobile {
    display: inline;
  }
  .pc {
    display: none;
  }
  /* .dimensions-info {
    flex-direction: column;
    align-items: center;
  } */
  .volume-price p {
    font-size: 22px;
  }
  .dropzone {
    font-size: 14px;
  }
  .dimensions-area {
    font-size: 12px;
  }
  .model-item {
    flex-direction: column;
  }
  .brand-name {
    font-size: 16px;
  }
  .pc-title {
    display:none;
  }
  .mobile-title {
    display:inline;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper{
    flex-direction: column;
    align-items: center;
  }
  .service-page-scale-wrapper{
    margin-top: 0;
  }
  .upload-container {
    padding: 20px 20px 20px 20px;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(1); /* 初期状態 */
  }
  to {
    transform: scale(1.05); /* 120%に拡大 */
  }
}
